
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
  color: var(--color-main);
  font-family: var(--font-family), serif;
  font-weight: var(--fw-regular);
  line-height: 1.5;
}

a {
  color: unset;
  text-decoration: none;
}

h1 {
  font-size: clamp(2rem, 5vw, 3rem);
}

h2 {
  font-size: clamp(1.3rem, 3.4vw, 1.6rem);
}

h3 {
  font-weight: var(--fw-bold);
  font-size: clamp(1rem, 2.5vw, 1.2rem);
}

p {
  font-size: clamp(1rem, 2.5vw, 1.2rem);
  margin-top: 15px;
}

span {
  font-weight: 800;
}

ul {
  font-size: clamp(1rem, 2.5vw, 1.2rem);
  list-style-position: inside;
  margin-left: 10px;
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section {
  width: 70%;
  margin: 3% auto auto;
  scroll-margin-top: 100px;
  margin-bottom: 50px;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media screen and (max-width: 990px) {
  .section {
    /* margin-top: 100px; */
    width: 85%;
  }
}