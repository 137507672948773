.button {
  cursor: pointer;
  padding: .8rem 3rem;
  border-radius: 4px;
  font-weight: 500;
}

.button:hover {
  transition: ease-in-out all 250ms;
}

.btn-primary {
  background: transparent;
  color: var(--color-main);
  border: 1px solid var(--color-main);
  text-transform: uppercase;
}
.btn-primary:hover {
  background: var(--color-main);
  color: var(--color-bg);
}

.btn-secondary {
  padding: 1rem 1.5rem;
  border-radius: 30px;
  background: var(--color-5);
  color: var(--color-main);
  border: 1px solid var(--color-main);
  text-transform: capitalize;
}
.btn-secondary:hover {
  background: var(--color-main);
  color: var(--color-5);
}