@media screen and (max-width: 990px) {
    .carousel-item {
        width: 100% !important;
    }

    .carousel-arrows {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .carousel-control-prev {
        position: relative !important;
    }

    .carousel-control-next {
        position: relative !important;
    }
}
