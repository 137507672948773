.video-container {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding-top: 56.25%;
    overflow: hidden;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 20px;
    overflow: hidden;
    scale: 0.8;
}

@media screen and (max-width: 990px) {

    .video-container,
    .react-player {
        scale: 1;
    }
}
