.icons {
  display: flex;
  gap: 10px;
}

.facebook:hover,focus {
  color: rgb(7,102,255);
  transition: ease-in-out all 200ms;
}

.instagram:hover,focus{
  background: linear-gradient(132deg, rgba(249, 206, 52, 1) 0%, rgba(238, 42, 123, 1) 50%, rgba(98, 40, 215, 1) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: ease-in-out all 200ms;
}

.youtube:hover,focus {
  color: rgb(255, 0, 0);
  transition: ease-in-out all 200ms;
}

.spotify:hover,focus {
  color: rgb(28, 215, 96);
  transition: ease-in-out all 200ms;
}