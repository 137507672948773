.footer {
  min-height: 200px;
  margin-top: 100px;
}

.footer-divider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.footer-icons {
  display: flex;
  flex-direction: row;
  gap: 30px;
  font-size: 25px;
}

.hline {
  content: "";
  display: block;
  height: 1px;
  width: 40%;
  background-color: var(--color-main);
}

.footer-copy {
  display: block;
  text-align: center;
  margin: 20px;
}

.footer-copy h3,
.footer-copy p {
  font-size: .7rem;
  padding: 0;
  margin: 0;
}
.footer-copy p {
  font-style: italic;
}