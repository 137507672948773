.contacts-formatted {
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  text-align: center;
  font-weight: 600;
}

.contacts {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
}


@media screen and (max-width: 990px) {
  .contacts {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 30px;
    gap: 10px;
    margin-bottom: 60px;
  }
}