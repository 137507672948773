.hero-image {
  height: 100%;
  top:0;
  margin: 0;
  position: relative;

  background-image: url("../../assets/img/gruppo-larger.png");
  background-position: 0 30%;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-section {
  padding-top: 20%;
  padding-bottom: 15%;
  text-align: center;
}

.hero-title {
  font-size: clamp(3rem, 8vw, 5rem);
  text-transform: uppercase;
}

.hero-icons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: clamp(0.8rem, 2vw, 1.3rem);
}

@media screen and (max-width: 990px) {
  .hero-image {
    background-position: top;
  }

  .hero-section {
    padding-top: 60%;
  }

  .hero-title {
    font-size: clamp(4rem, 8vw, 6rem);
  }

  .hero-icons {
    font-size: clamp(1.3rem, 8vw, 1.5rem);
  }
}






