.navbar {
  --bs-navbar-padding-y: .1rem !important;
  --bs-navbar-padding-x: 1rem !important;
  --bs-navbar-brand-margin-end: 0 !important;
  --bs-gutter-x: 0 !important;
  z-index: 1;
  position: fixed !important;
  top: 0;
  width: 100%;
  box-shadow: 0 0 20px rgba(227, 228, 237, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.18);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.navbar-nav {
  --bs-nav-link-color: var(--color-2) !important;
  --bs-nav-link-hover-color: var(--color-4) !important;
  --bs-navbar-active-color: var(--color-4) !important;
  --bs-nav-link-font-size: 1.3rem;
  --bs-navbar-nav-link-padding-x: 1vw;

  justify-content: center;
  flex-direction: row;
  width: 100%;
}

.navbar-toggler {
  background-color: var(--color-2) !important;
  border: none !important;
  min-height: 50px;
}

